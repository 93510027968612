import React from 'react';
import Layout from '../components/Layout';

const Contacts = () => (
  <Layout>
    <h1>This is contacts page!</h1>
  </Layout>
);

export default Contacts;
